import React from 'react';
import { Paragraph } from '@contentful/f36-components';
import { Button } from '@contentful/f36-components';
import { PageAppSDK } from '@contentful/app-sdk';
import { /* useCMA, */ useSDK } from '@contentful/react-apps-toolkit';
import Auth from '../components/Auth';

interface FrameWrapperProps {
  id: string;
  title: string;
  [key: string]: any;
}

const FrameWrapper = (props: FrameWrapperProps) => {
  /* eslint-disable jsx-a11y/iframe-has-title */
  const frame = (
    <iframe
      width="100%"
      frameBorder="0"
      {...props}
      style={{
        'min-height': '99vh',
        ...props.style
      }}
    />
  );
  /* eslint-enable jsx-a11y/iframe-has-title */

  return frame;
};

const Page = () => {
  const sdk = useSDK<PageAppSDK>();
  /*
     To use the cma, inject it as follows.
     If it is not needed, you can remove the next line.
  */
  // const cma = useCMA();

  // return <Paragraph>Hello Page Component (AppId: {sdk.ids.app})</Paragraph>;

  // return (
  //   <FrameWrapper
  //     id="burnerpage-frame"
  //     title="BurnerPage"
  //     // src="https://admin.burner.page/admin/auth/signin"
  //     src="https://local.burner.page:3440/admin/"
  //   />
  // );

  // return <Button variant="primary" isFullWidth as="a" href="https://admin.burner.page/" target="_blank">Launch BurnerPage</Button>

  return <Auth mode="page" />;
};

export default Page;
