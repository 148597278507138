import {
  Button,
  Flex,
  Form,
  Heading,
  Paragraph,
  TextLink
} from '@contentful/f36-components';
import tokens from '@contentful/f36-tokens';
import { css, cx } from 'emotion';
import logo from '../Ab3Logo.svg';

interface Props {
  mode: 'config' | 'page';
}

const Auth = ({ mode }: Props) => {
  return (
    <>
      <div
        className={cx(
          'background',
          css({
            display: 'block',
            position: 'absolute',
            zIndex: -1,
            top: 0,
            width: '100%',
            height: '300px',
            backgroundColor: '#5734F6'
          })
        )}
      ></div>
      <Flex
        flexDirection="column"
        className={css({
          maxWidth: '768px',
          height: 'auto',
          minHeight: '65vh',
          margin: '0 auto',
          marginTop: tokens.spacingXl,
          padding: tokens.spacingXl + ' ' + tokens.spacing2Xl,
          backgroundColor: '#fff',
          boxShadow: '0 0 20px rgba(0, 0, 0, .1)',
          borderRadius: tokens.borderRadiusSmall,
          justifyContent: 'center'
        })}
      >
        <Form>
          <div
            className={cx(
              'logo',
              css({
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginBottom: tokens.spacingXl
              })
            )}
          >
            <img
              src={logo}
              alt="AB3.ai"
              className={css({ height: '40px' })}
            />
          </div>
          <Heading>AB3.ai for Contentful</Heading>
          <Paragraph>
            Our AI tests multiple page variations, tracks their
            performance, and evolves by generating new versions,
            ensuring your page always gets better.{' '}
            <TextLink
              href="https://ab3.ai/?utm_campaign=contentful_app_config"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more.
            </TextLink>
          </Paragraph>
          <Button
            variant="primary"
            as="a"
            href="https://admin.burner.page/?utm_campaign=contentful_app_config"
            target="_blank"
            className={cx(
              'launch-button',
              css({
                marginTop: tokens.spacingXl,
                backgroundColor: 'rgb(90 54 255)',
                fontWeight: tokens.fontWeightDemiBold,
                '&:hover': css({
                  backgroundColor: 'rgb(90 54 255)',
                  filter: 'brightness(1.175)'
                })
              })
            )}
            isFullWidth
          >
            Launch BurnerPage
          </Button>
        </Form>
      </Flex>
    </>
  );
};

export default Auth;
